<template>
    <div>
        <b-card no-body class="p-1 mb-2">
            <div class="row">
                <b-col cols="3">
                    <router-link
                        v-if="accessForCreateQualityControlAct"
                        :to="{ name: 'QualityControlActCreate' }"
                        class="btn btn-success"
                    >
                        <b-icon-plus scale="1.2"/>
                    </router-link>
                </b-col>
                <b-col cols="9" class="text-right">
                    <b-button variant="primary" @click="applyFilters" class="mr-1">
                        <b-icon-arrow-repeat font-scale="1.2"/>
                    </b-button>
                    <b-button v-b-toggle.filters variant="light">
                        <b-icon-filter/>
                        Фильтры
                    </b-button>
                    <small v-if="filtersExists">
                        <b-link @click.prevent="resetFilters">(Сбросить)</b-link>
                    </small>
                </b-col>
            </div>
            <b-collapse id="filters">
                <div class="card-body">
                    <b-row>
                        <b-col md="3">
                            <b-form-group description="Дата от">
                                <input type="date" class="form-control" v-model="filters.dateFrom"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group description="Дата до">
                                <input type="date" class="form-control" v-model="filters.dateTo"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group description="Склад">
                                <storage-picker v-model="filters.storage" :allowedOnly=true>
                                </storage-picker>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group description="Причина">
                                <quality-control-act-reason-input v-model="filters.reason"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group description="Передача">
                                <b-form-select v-model="filters.transfer">
                                    <option :value="null">Все</option>
                                    <option :value="true">Есть</option>
                                    <option :value="false">Нет</option>
                                </b-form-select>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
            </b-collapse>
        </b-card>

        <b-table
            :items="items"
            :fields="fields"
            @row-dblclicked="towardsActForm"
            responsive
            head-variant="dark"
            no-local-sorting
        >
            <template #head(transfer)="data">
                <b-icon icon="circle-fill" class="text-light"/>
            </template>

            <template #cell(transfer)="row">
                <b-icon icon="circle-fill" :class="row.item.transferClass" :title="row.item.title"/>
            </template>

            <template #cell(actions)="data">
                <b-button-group>
                    <b-button size="sm" variant="info" :to="routerActShow( data.item.id )">
                        <b-icon-eye-fill/>
                    </b-button>
                    <b-button
                        size="sm"
                        variant="warning"
                        :to="routerActForm( data.item.id )"
                        v-if="accessForCreateQualityControlAct"
                    >
                        <b-icon-pencil-square/>
                    </b-button>

                    <b-button
                        size="sm"
                        variant="danger"
                        @click="cancellation( data.item )"
                        v-if="!data.item.canceled && accessForCreateQualityControlAct"
                    >
                        <b-icon-trash-fill/>
                    </b-button>
                    <b-button
                        size="sm"
                        variant="success"
                        @click="cancellation( data.item )"
                        v-else-if="accessForCreateQualityControlAct"
                    >
                        <b-icon-check2-square/>
                    </b-button>
                </b-button-group>
            </template>
        </b-table>

        <b-row>
            <b-col md="6">
                <span>
                    Показано с {{ meta.current_page * meta.per_page - meta.per_page + 1 }}
                    по {{ meta.current_page * meta.per_page - meta.per_page + items.length }}
                    из {{ meta.total_rows }} записей
                </span>
            </b-col>
            <b-col md="6">
                <b-pagination
                    v-model="meta.current_page"
                    :total-rows="meta.total_rows"
                    :per-page="meta.per_page"
                    @input="applyFilters"
                    class="justify-content-end"
                />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import moment from 'moment';
import lsfMixin from '@utils/localStorageFilterMixin';
import {ROLE_QUALITY_CONTROL} from '@utils/Roles';
import StoragePicker from '@components/_common/StoragePicker';
import QualityControlActReasonInput from './components/QualityControlActReasonInput';
import {QUALITY_CONTROL_ACT_ENDPOINT} from '@utils/endpoints';

const RECORD_PER_PAGE = 25;

const FILTERS_PATTERN = {
    dateTo: null,
    dateFrom: null,
    storage: null,
    reason: null,
    transfer: null,
};

export default {
    name: 'Index',
    mixins: [lsfMixin],
    components: {StoragePicker, QualityControlActReasonInput},
    data() {
        return {
            totalRows: 0,
            currentPage: 1,
            items: [],
            fields: [
                {key: 'transfer'},
                {key: 'id', label: '№'},
                {key: 'date', label: 'Дата'},
                {key: 'nomenclatures', label: 'Номенклатуры'},
                {key: 'storage', label: 'Склад'},
                {key: 'reason', label: 'Причина'},
                {key: 'actions', label: 'Действия'}
            ],
            meta: {
                total_rows: 0,
                current_page: 1,
                per_page: 25,
            }
        };
    },

    watch: {
        filters: {
            deep: true,
            handler(value) {
                this.watchFilters(value);
            }
        }
    },

    computed: {
        preparedConditions() {
            let conditions = {
                with: [
                    'reason',
                    'storage',
                    'transferAct',
                    'items.nomenclature'
                ],
                sort: 'date',
                sortDirection: 'desc',
            };

            for (let key in this.getFilters) {
                if (this.getFilters[key]) {
                    switch (key) {
                        case 'dateTo':
                            conditions['filters[date_to]'] = this.getFilters[key] ? moment(this.getFilters[key]).add(1, 'days').format('YYYY-MM-DD') : null;
                            break;
                        case 'dateFrom':
                            conditions['filters[date_from]'] = moment(this.getFilters[key]).format('YYYY-MM-DD');
                            break;
                        case 'storage':
                            conditions['filters[storage_id]'] = this.getFilters[key].id;
                            break;
                        case 'reason':
                            conditions['filters[reason_id]'] = this.getFilters[key].id;
                            break;
                        case 'transfer':
                            conditions['filters[hasTransfer]'] = this.getFilters[key] ? 1 : 0;
                            break;
                        default:
                            conditions[key] = this.getFilters[key];
                            break;
                    }
                }
            }

            conditions.page = this.meta.current_page;

            return conditions;
        },

        accessForCreateQualityControlAct() {
            return this.$auth
                .user()
                .roles
                .some(role => [ROLE_QUALITY_CONTROL].includes(role));
        }
    },
    methods: {
        routerActShow(id) {
            return {name: 'QualityControlActShow', params: {id: id}};
        },
        routerActForm(id) {
            return {name: 'QualityControlActUpdate', params: {id: id}};
        },
        towardsActForm(record) {
            this.$router.push(this.routerActForm(record.id));
        },
        prepareItems(items) {
            let preparedItems = [];

            items.forEach((item) => {
                let transferClass = item.transferAct ? 'text-info' : 'text-dark';

                let row = {
                    id: item.id,
                    transferClass: transferClass,
                    canceled: item.canceled,
                    storage: item.storage.name,
                    reason: item.reason.comment,
                    nomenclatures: item.items.map((item) => item.nomenclature.name).join(', '),
                    date: moment(item.date).format('DD.MM.YYYY HH:mm'),
                    _rowVariant: item.canceled ? 'danger' : null
                };

                preparedItems.push(row);
            })

            return preparedItems;
        },

        cancellation(item) {
            let data = {canceled: !item.canceled};

            this.$http
                .put(QUALITY_CONTROL_ACT_ENDPOINT + `/${item.id}/cancel`, data)
                .then(() => this.applyFilters())
                .catch((response) => {
                    this.$bvToast.toast(response.data.message ?? 'Ошибка!', {variant: 'danger'});
                });
        },

        applyFilters() {
            this.items = [];
            let params = this.preparedConditions;

            this.$http
                .get(QUALITY_CONTROL_ACT_ENDPOINT, {params})
                .then(response => {
                    this.meta = {
                        total_rows: response.data.data.total,
                        current_page: response.data.data.current_page,
                        per_page: response.data.data.per_page,
                    }
                    this.items = this.prepareItems(response.data.data.data);
                });
        },
    },

    created() {
        this.initFilters(this, FILTERS_PATTERN);
    }
}
</script>
